import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import NavBar from "./components/Navbar/Navbar";
import NAV from "./components/Navbar/NAV";
import Buy from "./pages/buy/Buy";

function App() {
  return (
    <>
      <Router>
        <NavBar />
        <NAV />
        <Switch>
          <Route path="/about">
            <Home />
          </Route>
          <Route path="/howtobuy">
            <Buy />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
